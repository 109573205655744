import useAuth from 'hooks/useAuth';
import React, { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AxiosService from 'services/Axios';

const FortuneContext = createContext();

const FortuneProvider = ({ children }) => {
  const [fortune, setFortune] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if(user?.name && user?.dob && !fortune){
      getFortune();
    }
  }, [user])

  const getFortune = async () => {
    try {
      const res =  await AxiosService.get(`${process.env.REACT_APP_API_URL}/auth/fortune`);
      await setFortune(res.data.responseObject);
    } catch (error) {
      toast.error('데이터를 불러오는데 오류가 발생핬습니다.', error);
//      toast.error('Failed to fetch data:', error);
      throw error;
    } finally {
    }
  };

  return (
    <FortuneContext.Provider value={{ fortune, getFortune }}>
      {children}
    </FortuneContext.Provider>
  );
};

export { FortuneContext, FortuneProvider };
